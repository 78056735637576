.thank-you-page {
  text-align: center;
  margin-top: 60px;
}

.thank-you-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.thank-you-page button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #b809b8;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.thank-you-page button:hover {
  background-color: #w;
}

.container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.logo-container img {
  width: 200px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
}

.footer {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.footer img {
  max-width: 600px;
}