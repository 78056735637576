.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.logo-container img {
  width: 200px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
}

.footer {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.footer img {
  width: 100%;
}

.form-wrapper {
  width: 100%;
  max-width: 1000px;
  border-radius: 5px;
}

.form-wrapper h1, h2, h3 {
  text-align: center;
}

.form-wrapper h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Oswald';
  font-size: 1.4rem;
}

.form-wrapper h3 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.form-wrapper form {
  display: flex;
  flex-direction: column;
}

.form-wrapper label {
  margin-bottom: 5px;
  margin-left: 1px;
}

.form-wrapper input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  box-sizing: border-box;
  width: 100%;
}

.form-wrapper input[type=file] {
  max-width: 300px;
}

.form-wrapper button {
  min-width: 200px;
  padding: 15px 0;
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #b809b8;
  font-size: 1.2rem;
  margin: 0.8rem 1rem;
  box-shadow: 2px 2px 7px 2px #ddd;
}

.form-wrapper button:hover {
  background-color: #c31ec3;
  cursor: pointer;
}

.form-wrapper #placeOrderBtn {
  background-color: #35c61f;
}
.form-wrapper #placeOrderBtn:hover {
  background-color: #55ca43;
  cursor: pointer;
}

input:hover, textarea:hover {
  box-shadow: 0 0 4px 2px #e3c9ff;
}
input:focus, textarea:focus {
  outline: none;
  border: 3px solid #800080;
  border-radius: 6px;
  box-shadow: 0 0 5px 3px #e3c9ff
}

label {
  font-size: 0.9rem;
}

textarea {
  font-family: sans-serif;
  /* Additional CSS properties for the textarea */
}

::placeholder {
  font-size: 0.9rem;
}

.info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 35px;
  justify-content: center;
  overflow: none;
  margin: 0.5rem auto 2.5rem auto;
  padding: 2rem 2.5rem 3rem 2.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 700px;
}

.info-row input {
  min-height: 40px !important;
}
.gradient {
  background: linear-gradient(0deg, #f1f1f1, #ffffff);
}

.info-container input {
  min-height: 22px;
}

.info-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.info-row {
  display: flex;
  flex-direction: column;
  flex-wrap: flex;
}

.client-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

/*
.form-column {
  flex-basis: 48%; 
}
*/

.signs-container {
  width: 99%;
}

.sign-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  row-gap: 10px;
  column-gap: 20px;
  justify-content: center;
}

.sign-row {
  width: 100%;
  height: 75px;
  display: grid;
  grid-template-columns: 3fr 7fr 2fr; /* Set the column widths using fractions */
  gap: 10px;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0.25rem 0.1rem 0.25rem 0.1rem;
  border-radius: 10px;
  box-shadow: 0 0 3px 2px #fefefe;
}

.sign-row:hover {
  box-shadow: 0 0 3px 2px #eee;
}

.sign-row.quantity-selected {
  background-color: rgb(171, 255, 198);
}

.image-column {
  width: 100%;
  text-align: left;
  vertical-align: center;
}

.name-column {
  width: 100%;
}

.name-column .sign-name {
  font-weight: 400;
}

.name-column .sign-size {
  font-size: 0.75rem;
  margin-top: 0.15rem;
  font-weight: 300;
}

.name-column span {
  display: block;
}

.quantity-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.quantity-column label {
  font-size: 0.65rem;
}

.quantity-column input {
  max-width: 45px !important;
  width: 100%;
  margin: 0 2px 0 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.image-wrapper {
  max-width: 100%;
  height: auto;
  text-align: center;
  object-fit: contain;
}

.image-wrapper img {
  max-width: 90%;
  max-height: 70px;
  border-radius: 5px;
  margin: 0;
  padding: 2px;
}

.description-container {
  display: flex;
  flex-direction: column;
  margin: 3rem auto 1rem auto;
  width: 100%;
  max-width: 600px;
}

.description-container label {
  margin-bottom: 8px;
  text-align: center;
}

.description-container textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
}


.submit-container {
  margin: 40px auto 0 auto;
  text-align: center;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-upload-button {
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px; /* Add margin to create spacing between the button and uploaded files */
  align-self: flex-start; /* Align the button to the left side of the container */
}

.uploaded-files {
  padding: 0.5rem;
  margin-top: 10px; /* Add margin to create spacing between the button and uploaded files */
}

.uploaded-files h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
}

.uploaded-files ul {
  list-style-type: none;
  padding: 0 0 0 5px;
}

.uploaded-files li::before {
  content: "\2713"; /* Unicode character for checkmark */
  margin-right: 5px;
  margin-bottom: 5px;
  color: #2ca519;
}

.file-upload {
  position: relative;
  display: inline-block;
}

.file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #eaeaea;
  color: #333;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #e1e1e1; 
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .logo-container {
    justify-content: center !important;
  }

  .sign-section {
    grid-template-columns: 1fr;
    justify-self: center;
  }

  .info-container {
    grid-template-columns: 1fr;
    margin: 0.5rem 0 3.5rem 0;
  }

  .info-row {
    width: 100%;
    margin: auto;
  }

  .quantity-column {
    padding-right: 0.5em;
  }

  h1 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }

  .form-wrapper {
    margin: auto;
  }
  .form-wrapper button {
    min-width: 300px;
    margin: 1rem auto;
  }

  /* Target the date input field */
  input[type=date] {
    height: 38px;
    font-size: 0.9rem;
    background-color: #fff;
  }

  .submit-container button {
    margin: 1rem;
  }
}
