.Home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.btn {
  padding: 20px 60px;
  background-color: steelblue;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 2rem;
  border-radius: 10px;
}